<script setup lang="ts">
import type { IComparedData } from '@audit'

import type { IPagination } from '@shared/composables/usePagination'
import useColumnLoading from './composables/useColumnLoading'
import { useScheduleComparisonColumns } from './composables/useScheduleComparisonColumns'
import type { DataTableColumn } from '~/layers/ui/components/DataTable/types'

interface Props {
  data: IComparedData[]
  columns: string[]
  loading?: boolean
  pagination?: IPagination
  total?: number
  selectedRows?: IComparedData[]
}
const props = withDefaults(defineProps<Props>(), {
  loading: false,
  pagination: () => ({ page: 1, pageSize: 25 }),
  total: 0,
  selectedRows: () => [],
})

const colsSkeleton = useColumnLoading()
const formattedColumns = useScheduleComparisonColumns(
  computed(() =>
    props.columns.length
      ? props.columns.map((col, index) => ({
          dataFieldName: col,
          displayName: col,
          dataType: 'string',
          order: index,
        }))
      : colsSkeleton,
  ),
)
</script>

<template>
  <div
    class="my-4 flex w-full overflow-x-auto rounded-lg border border-gray-300 p-0"
  >
    <p v-if="!loading && columns.length === 0" class="h-[460px] p-5">
      No Schedule Variance
    </p>
    <DataTable
      v-else
      :total-records="total"
      :columns="formattedColumns"
      :data="data"
      :loading="loading"
      class="h-[460px] w-full min-w-max rounded-lg"
    >
      <template #empty>
        <div class="flex flex-col items-center justify-center pb-12 pt-16">
          <h1>No reconciliation found</h1>
          <Icon name="no-lease" class="my-8 text-8xl text-gray-200" />
        </div>
      </template>
      <template #item="{ id, item: obj }: DataTableColumn<IComparedData>">
        <span
          v-if="id === 'Tenant'"
          :class="{ 'text-red-500': obj[id].matchResult === 'valuesMatch' }"
        >
          {{ obj[id].extractedValue || obj[id].accuraitValue }}
        </span>
        <LazyComparisonRentScheduleVarianceTableField v-else :value="obj[id]" />
      </template>
    </DataTable>
  </div>
</template>
<style scoped>
:deep(table) {
  width: max-content;
}

:deep(tbody > tr:last-child) {
  @apply !border-gray-150;
  td {
    @apply !text-base;
  }
  td:first-child {
    @apply font-medium text-white;
  }
}
</style>
